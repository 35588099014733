import React, { useState } from 'react';
import { Handle } from 'reactflow';
import { CiEdit } from 'react-icons/ci';
import { CgFileRemove } from 'react-icons/cg';
import { IoRemove } from 'react-icons/io5';
import { HexColorPicker } from 'react-colorful';

const CustomNode = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(data.label);
  const [value, setValue] = useState(data.value);
  const [backgroundColor, setColor] = useState(data.color);


  const handleEditClick = () => {
    setIsEditing(true);
    console.log(data);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    var newValue = value.split("\n\n")   // Split paragraphs by two line breaks
                .map(paragraph => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`) // Add <p> tags and <br> tags
                .join("\n");
    data.onEdit(data.id, label, newValue, backgroundColor);


  };

  if (isEditing) {
    return (
      <div className="nodrag" style={{ padding: '15px', backgroundColor: backgroundColor, border: '1px solid #ddd', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px',}}>
          <input type="text" value={label} onChange={(e) => setLabel(e.target.value)} />
          <textarea value={value} onChange={(e) => setValue(e.target.value)} />
          <HexColorPicker color={backgroundColor} onChange={setColor} />
        </div>
        <div style={{ paddingTop: '10px', display: 'flex', gap: '10px' }}>
          <button onClick={() => setIsEditing(false)}>cancel</button>
          <button onClick={handleSaveClick}>Save</button>
        </div>
      </div>
    );
  }

function getContrastTextColor(bgColor) {
    console.log(bgColor);
    // Remove the # if present
    bgColor = bgColor.replace('#', '');

    // Expand shorthand 3-digit hex code to 6 digits
    if (bgColor.length === 3) {
        bgColor = bgColor.split('').map(char => char + char).join('');
    }

    // Parse the hex color into RGB components
    const r = parseInt(bgColor.substring(0, 2), 16) / 255;
    const g = parseInt(bgColor.substring(2, 4), 16) / 255;
    const b = parseInt(bgColor.substring(4, 6), 16) / 255;

    // Calculate relative luminance
    const lumR = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    const lumG = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    const lumB = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    const luminance = 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;

    console.log('Luminance Components:', lumR, lumG, lumB);
    console.log('Total Luminance:', luminance);

    // Return black or white based on luminance threshold
    return luminance > 0.179 ? '#000000' : '#FFFFFF';
}



  return (
    <div key={data.id} style={{ borderRadius: '20px', padding: '20px', backgroundColor: data.backgroundColor ?? '#fff', border: '1px solid #ddd', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Top Handle */}
      <Handle type="target" position="top" id="a" style={{ top: 0, left: '50%', transform: 'translateX(-50%)' }} />
      <div style={{display: 'flex', justifyContent: 'space-between', gap: '10px', width: '200px', color: getContrastTextColor(data.backgroundColor ?? '#fff')}}>
        <div>
            <strong>{data.label}</strong>
            {/* Dangerously display html of data.value */}
            <div dangerouslySetInnerHTML={{ __html: data.value }} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <button onClick={handleEditClick} style={{  padding: '7.5px' }}>
                {/** Edit button icon from react-icons */}
              <CiEdit style={{fill: '#fff'}} />

            </button>
            <button onClick={handleEditClick} style={{ marginTop: '5px', padding: '7.5px', backgroundColor: '#DD9086' }}>
                {/** Edit button icon from react-icons */}
              <IoRemove style={{fill: '#fff'}} />

            </button>
        </div>
      </div>
      {/* Bottom Handle */}
      <Handle type="source" position="bottom" id="b" style={{ bottom: 0, left: '50%', transform: 'translateX(-50%)' }} />
      {/* Left Handle */}
      <Handle type="target" position="left" id="c" style={{ left: 0, top: '50%', transform: 'translateY(-50%)' }} />
      {/* Right Handle */}
      <Handle type="source" position="right" id="d" style={{ right: 0, top: '50%', transform: 'translateY(-50%)' }} />
    </div>
  );
};

export default CustomNode;
