// filename: Doc.js
// description: This file contains the code to display and edit a document.

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CiCirclePlus, CiEdit } from "react-icons/ci";
import ProjectForm from './ProjectForm';
import Mongapi from './mongoUtil';
import { useNavigate } from 'react-router-dom';
import DiagramEditor from './Diagram/DiagramEditor';

const Doc = () => {
    const { docId } = useParams();
    const [doc, setDoc] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(!isModalOpen);
    const closeModal = () => setModalOpen(false);
    var mongapiAgent = null

    const navigate = useNavigate();
    
    useEffect(() => {
        const mongapiAgent = new Mongapi('docs');
        const fetchDoc = async () => {
            try {
                const doc = await mongapiAgent.readDocumentById(docId);
                setDoc(doc);
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };
        fetchDoc();
    }, [docId]);


    const handleNewDiagram = () => {
        navigate(`/doc/${docId}/diagram`);
    };
   
    
    if (!doc) {
        return <div>Loading...</div>;
    }
    
    return (
        <div className='container'>
            <div id='general-info'>
                <div style={{display: 'flex', alignItems: 'start', gap: '25px'}}>
                    <div>
                        <h1>{doc.projectName}</h1>
                        <p>{doc.description}</p>
                    </div>
                    <div>
                        <button onClick={() => openModal()}>
                            <CiEdit />
                        </button>
                    </div>
                    
                    <ProjectForm isModalOpen={isModalOpen} closeModal={closeModal} docId={docId} />
                </div>
                <div>
                    
                </div>
            </div>
            <div id='diagrams' style={{display: 'flex', flexDirection: 'column', width: '200px', paddingTop: '50px'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', paddingBottom: '20px'}}>
                    <h3>Diagrams</h3>
                    <button style={{ backgroundColor: '#1993C8' }} onClick={handleNewDiagram}>
                        <CiCirclePlus />
                    </button>
                </div>
               {
                doc.diagrams && doc.diagrams.map((diagram) => (
                    <button onClick={() => navigate('/doc/' + docId + '/diagram/' + diagram.diagramId)}>
                        {diagram.diagramName}
                    </button>
                )
            )
               }
            </div>
        </div>
    );
}

export default Doc;