import React, { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Mongapi from '../mongoUtil';
import { useParams, useNavigate } from 'react-router-dom';
import Toolbar from './Toolbar';
import CustomNode from './CustomNode';
import NodeEditorForm from './NodeEditorForm';
import { FaCircleArrowLeft } from 'react-icons/fa6';

   const nodeTypes = {
    customNode: CustomNode,
  };
const DiagramEditor = () => {
  const { docId } = useParams();
  const { diagramId } = useParams();
  const navigate = useNavigate();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [connectMode, setConnectMode] = useState(true);
    const [editText, setEditText] = useState(''); // State for editing text
  const [editingNodeId, setEditingNodeId] = useState(null); // Node ID being edited
  const mongapiAgent = new Mongapi('docs');
    const [diagramName, setDiagramName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);

    const [docName, setDocName] = useState('');
    const [docDescription, setDocDescription] = useState('');


  useEffect(() => {
    const fetchDiagram = async () => {
      try {
        const doc = await mongapiAgent.readDocumentById(docId);
        const diagramData = doc.diagrams.find((diagram) => diagram.diagramId === diagramId);
        if (diagramData) {
            // add on edit function to the diagram data
            diagramData.nodes.forEach((node) => {
                node.data.onEdit = handleEditNode;
            });

          setNodes(diagramData.nodes);
          setEdges(diagramData.edges);
          setDiagramName(diagramData.diagramName);
          setDocName(doc.projectName);
          setDocDescription(doc.description);
        }
      } catch (error) {
        console.error('Error fetching diagram:', error);
      }
    };
    if (docId && diagramId) {
      fetchDiagram();
    }
  }, [docId, diagramId]);



  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  // Functions for Toolbar actions
  const addNode = (label, value) => {
    const id = `${+new Date()}`;
    const newNode = {
      id,
      position: { x: 250, y: 100 },
      data: { id: id, label: label || 'New Node', value: value || '', onEdit: handleEditNode, backgroundColor: '#fff'},
      style: { backgroundColor: '#fff' },
      type: 'customNode',
    };
    setNodes((nds) => nds.concat(newNode));
  };



  const changeColor = (color) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.selected ? { ...node, style: { ...node.style, backgroundColor: color } } : node
      )
    );
  };



  // Function to handle save from the NodeEditor
  const handleEditNode = (id, newLabel, newValue, backgroundColor) => {
    console.log('Edit node:', id, newLabel, newValue);
    // update the node with the values that are not null if value is null then keep the old value
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, 
          data: { 
            ...node.data, label: newLabel || node.data.label, 
            value: newValue || node.data.value,  
            backgroundColor: backgroundColor || node.data.backgroundColor,
          } } : node
      )
    );


  }



  const onSave = async () => {
    const diagramData = { nodes, edges, docId, diagramName };
    // add a diagramId to the diagram data
    if (!diagramData.diagramId) {
        const diagramId = `${+new Date()}`;
        diagramData.diagramId = diagramId;
    }
    const doc = await mongapiAgent.readDocumentById(docId);
    // add new diagram data to the document to the diagrams array
    const updatedDoc = {
        ...doc,
        diagrams: doc.diagrams.map((diagram) => (diagram.diagramId === diagramId ? diagramData : diagram)),
    };
    // actually add the diagram data to the document in the diagrams array if it doesn't exist
    if (!doc.diagrams.find((diagram) => diagram.diagramId === diagramId)) {
        updatedDoc.diagrams.push(diagramData);
    }
    console.log('Updated doc:', updatedDoc);
    //remove the id from the diagram data
    delete updatedDoc._id;

    // save the diagram data to the document (docId) with the id (diagramId)
    try {
      await mongapiAgent.updateDocument(docId, updatedDoc);
      navigate(`/doc/${docId}`);
    } catch (error) {
      console.error('Error saving diagram:', error);
      alert('Error saving diagram');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.key === 'Delete' || event.key === 'Backspace') && event.target.nodeName !== 'INPUT') {
        setNodes((nds) => nds.filter((node) => !node.selected));
        setEdges((eds) => eds.filter((edge) => !edge.selected));
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setNodes, setEdges]);

  return (
    <div style={{ height: '80vh', position: 'relative' }}>
      <div style={{backgroundColor: '#292929'}}>
        <div>
          <div style={{display: 'flex', gap: '10px',  }}>
            <button onClick={() => navigate('/doc/' + docId)}><FaCircleArrowLeft /></button>
            <h1 style={{color: 'white', paddingTop: '5px'}}>{docName}</h1>
          </div>
        </div>
      </div>
      <Toolbar
        onAddNode={addNode}
        onChangeColor={changeColor}
        onEditText={editText}
        diagramName={diagramName}
        setDiagramName={setDiagramName}
        onSave={onSave}
      />
      <div style={{height: '70vh'}}>

        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={connectMode ? onConnect : null}
            nodeTypes={nodeTypes}
            fitView
        >
            <MiniMap />
            <Controls />
            <Background />
        </ReactFlow>
      </div>
      

      
    </div>
  );
};

export default DiagramEditor;
