import React from 'react';
import { BrowserRouter as Router, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';
import logo from '../logo.png';

function CustomNavbar() {
   const navigate = useNavigate(); // Access useNavigate within a child component
   const { isLoggedIn, logout } = useAuth(); // Track login state



   const handleLogout = () => {
      logout() // Remove token from localStorage
      navigate('/login'); // Redirect to the login page
   };

   return (
      <nav className="navbar navbar-expand fixed-top" style={{ background: '#fff', height: '75px' }}>
         <div className="container-fluid">
           

            {/* Navbar Links */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav me-auto mb-lg-0">
                  {/* Home Link */}
                  <a href="/">
                     <img src={logo} alt="" style={{height: '40px'}} />
                  </a>
                  {
                     isLoggedIn &&
                     <li className="nav-item">
                        <NavLink
                           exact="true"
                           to="/"
                           className={({ isActive }) =>
                              isActive ? 'nav-link active' : 'nav-link'
                           }
                        >
                           Home
                        </NavLink>
                     </li>
                  }
                  {/* Dropdown Menu */}
                  <li className="nav-item dropdown">
                     <a
                        className="nav-link dropdown-toggle"
                        href="#!"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                     >
                        Account
                     </a>
                     <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {
                           !isLoggedIn &&
                           <>
                              <li>
                                 <NavLink
                                    to="/register"
                                    className={({ isActive }) =>
                                       isActive ? 'dropdown-item active' : 'dropdown-item'
                                    }
                                 >
                                    Create Account
                                 </NavLink>
                              </li>

                              <li>
                                 <NavLink
                                    to="/login"
                                    className={({ isActive }) =>
                                       isActive ? 'dropdown-item active' : 'dropdown-item'
                                    }
                                 >
                                    Login
                                 </NavLink>
                              </li>
                           </>
                        }

                     </ul>
                  </li>
               </ul>

               {/* Logout Button */}
               {
                  isLoggedIn &&
                  <button className="btn btn-danger" onClick={handleLogout}>
                     Logout
                  </button>
               }
            </div>
         </div>
      </nav>
   );
}

export default CustomNavbar;