import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Mongapi from './mongoUtil';

const ProjectForm = ({ onSubmit, isModalOpen, closeModal, docId }) => {
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');

    const mongapiAgentRef = useRef(null);


    const navigate = useNavigate();

    // if there is a docId, fetch the document and populate the form using mongoUtil
    useEffect(() => {
        if (mongapiAgentRef.current === null) {
            mongapiAgentRef.current = new Mongapi('docs');
        }
        if (docId) {
            const fetchDoc = async () => {
                try {
                    const doc = await mongapiAgentRef.current.readDocumentById(docId);
                    setProjectName(doc.projectName);
                    setDescription(doc.description);
                } catch (error) {
                    console.error('Error fetching document:', error);
                }
            };

            fetchDoc();
        }
    }, [docId]);
    

    const modalRef = useRef(null);
    // Close the modal when the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [modalRef, closeModal]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (docId) {
            // Update the document
            try {
                await mongapiAgentRef.current.updateDoc(docId, { projectName, description });
                console.log('Project updated successfully');
                closeModal();
            } catch (error) {
                console.error('Error updating project:', error);
            }
            return;
        }
        else {
            // Create a new document
            try {
                const response = await mongapiAgentRef.current.createDocument({ projectName, description });
                console.log('Project submitted successfully:', response);
                // Navigate to the project page
                navigate(`/doc/${response.id}`);
                closeModal();
            } catch (error) {
                console.error('Error submitting project:', error);
            }
        }
        
    };


    if (!isModalOpen) {
        return null;
    }
  return (
    <div style={overlayStyles}>

        <div style={modalStyles} ref={modalRef}>   
            <h2 className="text-center mb-4">Create Project</h2>
            <form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm bg-light">
            <div className="form-group mb-3">
                <label htmlFor="projectName" className="form-label">Project Name</label>
                <input
                type="text"
                className="form-control"
                id="projectName"
                placeholder="Enter project name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
                />
            </div>

            <div className="form-group mb-3">
                <label htmlFor="description" className="form-label">Description</label>
                <textarea
                className="form-control"
                id="description"
                rows="4"
                placeholder="Enter project description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                />
            </div>

            <button type="submit" className="btn btn-primary">Add Project</button>
            </form>
        </div>
    </div>

  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalStyles = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  maxWidth: '400px',
  width: '100%',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
};


export default ProjectForm;
