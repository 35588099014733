// Mongapi.js
import axios from 'axios';

const BASE_URL = 'https://mongapi.avacan.net';

class Mongapi {
  constructor(collection) {
    this.db = process.env.REACT_APP_NAME;
    this.collection = collection;

    // Create an Axios instance with default configurations
    this.api = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        db: this.db,
        collection: this.collection,
      },
    });

    // Add a request interceptor to include the Authorization header
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async createDocument(document) {
    try {
      const response = await this.api.post('/create', document);
      return response.data;
    } catch (error) {
      console.error('Error creating document:', error);
      throw error.response ? error.response.data : error;
    }
  }

  async readAllDocuments() {
    try {
      const response = await this.api.get('/read');
      return response.data;
    } catch (error) {
      console.error('Error reading documents:', error);
      throw error.response ? error.response.data : error;
    }
  }

  async readDocumentById(id) {
    try {
      const response = await this.api.get(`/read/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error reading document by ID:', error);
      throw error.response ? error.response.data : error;
    }
  }

  async updateDocument(id, updateData) {
    try {
      const response = await this.api.put(`/update/${id}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Error updating document:', error);
      throw error.response ? error.response.data : error;
    }
  }

  async deleteDocument(id) {
    try {
      const response = await this.api.delete(`/delete/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error.response ? error.response.data : error;
    }
  }



}

export default Mongapi;
