// App.js
import React, {useState} from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './Auth/AuthContext';
import ProtectedRoute from './Auth/ProtectedRoute';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import LoginForm from './Auth/Login';
import RegisterForm from './Auth/RegisterForm';
import CustomNavbar from './components/CustomNavbar';
import Doc from './Doc';
import DiagramEditor from './Diagram/DiagramEditor';


const App = () => {
  return (
    <AuthProvider>
      <Router>
        <CustomNavbar />
        <div style={{height: '75px'}}>
        </div>
        <Routes>
          <Route
            path="/"
            element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
          />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/doc/:docId" element={<Doc />} />
          <Route path="/doc/:docId/diagram/:diagramId" element={<DiagramEditor />} />
          <Route path="/doc/:docId/diagram" element={<DiagramEditor />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
