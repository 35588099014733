import React from 'react';

const Toolbar = ({ onAddNode, onStartConnectMode, onStopConnectMode, onChangeColor, onEditText, setDiagramName, diagramName, onSave }) => {
    // update the diagram name
    const handleDiagramNameChange = (event) => {
        setDiagramName(event.target.value);
    }
  return (
    <div>
        <div style={{ display: 'flex', padding: '10px', gap: '10px', background: '#f0f0f0', justifyContent: 'space-between', flexWrap: 'wrap', justifyItems: 'center' }}>
            <div style={{padding: '10px', display: 'flex', gap: '10px'}}>
                Diagram Name:
                <input type='text' defaultValue={diagramName}  onChange={handleDiagramNameChange} />
            </div>
            <button onClick={() => onAddNode('test', 'an add node test')}>Add Node</button>
            <button onClick={onSave}>
        Save Diagram
      </button>
        </div>
        
    </div>
  );
};

export default Toolbar;
