import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Mongapi from './mongoUtil';

const DocList = ({}) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mongapi = new Mongapi('docs');
  const [expandedDocID, setExpandedDocID] = useState(null);
  const colors = ['#FFB6C1', '#87CEFA', '#98FB98', '#FFA07A', '#DDA0DD']; // Light Pink, Light Blue, Pale Green, Light Salmon, Plum
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedDocuments = await mongapi.readAllDocuments();
        console.log(fetchedDocuments);
        

        if (fetchedDocuments.length > 0) {
          setDocuments(fetchedDocuments);
        } else {
          setError('No documents found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleExpandClick = (docID) => {
   setExpandedDocID(expandedDocID === docID ? null : docID);
 };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="row" style={{ width: '80vw', marginLeft: '10vw'}}>
      {documents.map((doc, index) => (
        <div className='document-postit-padding col-sm-12 col-md-6'>
          <div  className="document-postit " key={doc._id} style={{ backgroundColor: colors[index % colors.length], padding: '25px' }}>
            <div className="">
              <h5 className="mb-1">{doc.projectName}</h5>
              <small className="text-muted"></small>
            </div>
            <p className="mb-1">
            {expandedDocID === doc._id ? doc.description : `${doc.description.slice(0, 200)}...`}
              </p>
            <small className="text-muted"></small>
            <div style={{display: 'flex', gap: '20px', justifyContent: 'end', paddingTop: '20px'}}>
              <button onClick={() => navigate('/doc/' + doc._id)}>
                Open Doc
              </button>
              <button onClick={() => handleExpandClick(doc._id)}>
                {expandedDocID === doc._id ? 'Collapse' : 'Expand'}
              </button>
            </div>
          </div>
        </div>
          
      ))}
    </div>
  );
};

export default DocList;
