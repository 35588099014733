// filename: Dashboard.js

// Importing React and useState hook for managing component state
import React, { useState } from 'react'; 
// Importing ProjectForm component
import ProjectForm from './ProjectForm'; 
// Importing DocList component
import DocList from './DocList'; 

// Main functional component: Dashboard
const Dashboard = () => {
  // State to control modal visibility
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to toggle modal visibility
  const openModal = () => setModalOpen(!isModalOpen);

  // Function to close the modal
  const closeModal = () => setModalOpen(false);

  return (
    <div>
      {/* Red line for visual separation, positioned 40px from the left */}
      <div className="red-line"></div>

      {/* Main content section */}
      <div className="content">
        {/* Centered button section */}
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          {/* Button to open the modal for creating a document */}
          <button onClick={() => openModal()}>Create Document</button>
        </div>

        {/* Modal component that opens for creating a document */}
        <ProjectForm isModalOpen={isModalOpen} closeModal={closeModal} />

        {/* Document list component */}
        <DocList />

      </div>
    </div>
  );
};

export default Dashboard; // Exporting Dashboard component for use in other parts of the app
